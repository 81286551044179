exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-assets-assets-json-json-id-tsx": () => import("./../../../src/pages/assets/{AssetsJson.jsonId}.tsx" /* webpackChunkName: "component---src-pages-assets-assets-json-json-id-tsx" */),
  "component---src-pages-assets-tsx": () => import("./../../../src/pages/assets.tsx" /* webpackChunkName: "component---src-pages-assets-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-games-games-json-json-id-tsx": () => import("./../../../src/pages/games/{GamesJson.jsonId}.tsx" /* webpackChunkName: "component---src-pages-games-games-json-json-id-tsx" */),
  "component---src-pages-games-tsx": () => import("./../../../src/pages/games.tsx" /* webpackChunkName: "component---src-pages-games-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-tutorials-tsx": () => import("./../../../src/pages/tutorials.tsx" /* webpackChunkName: "component---src-pages-tutorials-tsx" */),
  "component---src-pages-tutorials-tutorials-json-json-id-tsx": () => import("./../../../src/pages/tutorials/{TutorialsJson.jsonId}.tsx" /* webpackChunkName: "component---src-pages-tutorials-tutorials-json-json-id-tsx" */)
}

